body {
    background-color: #d2edf4;
}

.page-container {
    max-width: 1000px;
}

.wrapper {
    max-width: 1650px;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #db1323;
    padding: 0px 0px 25px 0px;
}

.img-fluid {
    height: 100%;
    width: 100%;
}

.fill-img {
    padding: 0px;
    margin: 0px;
}

.fill-img img {
    object-fit: fill;
}

.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

.button, .button:focus {
    background-color: #e61610;
    color: white;
    min-width: 120px;
    text-transform: uppercase;
    font-size: smaller;
    border-radius: 0px;
    border: none;
    padding: 8px;
}

.description {
    color: rgb(53, 53, 53);
    cursor: pointer;
    font-size: 14px;
}

.button:hover {
    background-color: #e61710c4;
    color: white;
}

.button:disabled {
    background-color: #e617107c;
    color: white;
}

.form-control,
.form-select {
    border: 1px solid black;
    border-radius: 0px;
}

/* Navbar */
.navbar {
    background-color: #c8eef5;
    margin-left: auto;
    margin-right: auto;
    padding: 25px 150px 25px 50px;
    display: flex;
}

.nav-element-container {
    display: flex;
    flex-direction: row;
}

.nav-element {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: 0px 1px 0px 0px solid black;
    border-right: 1px solid black;
    font-size: 13px !important;
    transform: scale(0.9) !important;
    white-space: nowrap;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 20px 10px;
    position: relative;
}

.nav-element:hover .dropdown-content {
    display: block;
}

.nav-element:last-child {
    border-right: none;
}

.nav-element:nth-last-child(1) {
    display: inline-block;
    margin: 0px;
    border: none;
    font-size: small !important;
    transform: scale(0.9) !important;
}

.nav-element a {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
}

.nav-element a:hover {
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(219, 219, 219);
    transition: var(--trans2,color .4s ease 0s);
}

.logo {
    width: 236px;
}

.header {
    background-color: #c8eef5;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
}


.dropdown-content {
    position: absolute;
    top: 100%;
    left: 45%;
    transform: translateX(-50%);
    display: none;
    background-color: #f9f9f9;
    z-index: 1;
    min-width: 120px;
    width: max-content;
    text-align: center;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content li {
    list-style: none;
    padding: 10px;
    border-bottom: 1px solid black;
}

.dropdown-content li:nth-last-child(1) {
    list-style: none;
    padding: 10px;
    border-bottom: none;
}

@media all and (max-width: 700px) {
    .image-fluid {
        display: none;
    }

    .footer-box {
        text-align: center;
    }
}

@media all and (max-width: 610px) {

    .nav-element {
        display: none;
    }

    .nav-element:nth-child(1) {
        display: block;
    }

    .nav-element:nth-last-child(1) {
        display: block;
    }
}

.nav-select {
    background-color: black;
    color: white;
    border: none !important;
    padding: 8px 16px;
    min-width: 100px;
    font-size: 12px;
}

.nav-select,
.nav-select:active,
.nav-select:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

/* Footer */
.footer {
    margin-top: 100px;
}

.footer-box {
    background-color: #dbdbdb;
    min-height: 340px;
}

.footer-url {
    color: #000;
    text-decoration: underline;
}

@media all and (max-width: 500px) {
    .header {
        display: none !important;
    }
}

@media all and (min-width: 501px) {
    .mobile-header {
        display: none !important;
    }
}

.mobile-header .logo-div {
    padding-top: 10px;
    scale: 0.7;
}

.mobile-header .logo-div .fill {
    background-color: #d2edf4;
    padding-bottom: 0px;
}

#mobile-nav {
    background-color: white;
}

#mobile-nav ul {
    list-style: none;
    text-decoration: none;
    text-align: center;
    padding: 0px 40px;
    margin: 0px;
    font-weight: 600;
}

#mobile-nav ul li {
    padding: 10px;
    border-bottom: 1px solid black;
}

#mobile-nav ul li:nth-last-child(1) {
    border-bottom: none;
}

#mobile-nav a {
    text-decoration: none;
    color: black;
    text-transform: uppercase;
}

#mobile-nav ul li i {
    position: absolute;
    left: 60px;
}

.sub-menu {
    padding: 12px !important;
}

.sub-menu li {
    border: none !important;
    font-weight: 500 !important;
}